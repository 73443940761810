import React from "react";

import { AddToCartButton, OptionsWrapper } from "../../index";

import "./product-add-to-cart-control.css";

const ProductAddToCartControl = ({
  quantity,
  available,
  storefrontId,
  hasVariants,
  options,
  handleOptionChange
}) => (
  <div className="product-add-to-cart-control">
    {hasVariants && (
      <OptionsWrapper
        hasVariants={hasVariants}
        options={options}
        handleOptionChange={handleOptionChange}
      />
    )}
    <div className="product-add-to-cart-control--buttons">
      <AddToCartButton
        variantId={storefrontId}
        quantity={quantity}
        available={available}
      />
    </div>
  </div>
);

export default ProductAddToCartControl;
