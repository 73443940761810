import React, { useEffect, useState, useContext, useCallback } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import isEqual from "lodash.isequal";
import { formatPrice } from "../../utils/format-price";
import { StoreContext } from "../../context/store-context";

// components
import { Section, Breadcrumbs, Seo } from "../../components/index";
import { BookGallery, BookInfo } from "./index";
import ProductAddToCartControl from "../../components/Cart/ProductAddToCartControl/ProductAddToCartControl";
import Layout from "../../components/Layout";
import CardBadge from "../../components/Cards/CardBadge/CardBadge";

// styles
import { Container } from "../../styles/Container";

const BookTemplate = ({ data, pageContext }) => {
  const { client } = useContext(StoreContext);

  const { title, cover, gallery, categories } = data.airtable.data;
  const productInfo = data.airtable.data;

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    totalInventory
  } = data.shopify;

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(data.shopify, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const handleOptionChange = (index, event) => {
    const value = event.target.value;

    if (value === "") {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value
    };

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(data.shopify.storefrontId);
  }, [
    productVariant.storefrontId,
    checkAvailablity,
    data.shopify.storefrontId
  ]);

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  );

  const comparePrice = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.compareAtPrice
  );

  const hasVariants = variants.length > 1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title={pageContext.title} />
      <Breadcrumbs pageContext={pageContext} />
      <Section customClass="section--no-margin-top">
        <BookTemplateContainer>
          <div className="wrapper">
            <div className="images">
              <div className="cover" style={{ position: "relative" }}>
                <GatsbyImage
                  image={getImage(cover?.localFiles[0])}
                  alt={title}
                />
                <CardBadge categories={categories} />
              </div>
              <BookGallery
                gallery={gallery.localFiles}
                title={productInfo.title}
                className="book-template--gallery"
              />
            </div>
          </div>
          <div>
            <BookInfo
              product={productInfo}
              price={price}
              comparePrice={comparePrice}
              totalInventory={totalInventory}
            >
              {totalInventory > 0 && (
                <ProductAddToCartControl
                  quantity={quantity}
                  storefrontId={productVariant.storefrontId}
                  available={available}
                  hasVariants={hasVariants}
                  options={options}
                  handleOptionChange={handleOptionChange}
                />
              )}
            </BookInfo>
            <BookGallery
              gallery={gallery.localFiles}
              title={productInfo.title}
              print_image={productInfo.print_image}
              description="Book Details"
              className="book-template--mobile-gallery"
            />
          </div>
        </BookTemplateContainer>
      </Section>
    </Layout>
  );
};

const BookTemplateContainer = styled(Container)`
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 3.5fr 2fr;
    gap: 3.2rem;
  }
  .wrapper {
    @media screen and (min-width: 992px) {
      position: relative;
      min-height: 100vh;
      .images {
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        overflow-y: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;
      }
      .images::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .cover {
        margin-bottom: 0;
      }
    }
  }
`;

export const query = graphql`
  query GetSingleBook($SKU: String!) {
    airtable(data: { SKU: { eq: $SKU } }) {
      data {
        ...BookParts
      }
    }
    shopify: shopifyProduct(variants: { elemMatch: { sku: { eq: $SKU } } }) {
      title
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        availableForSale
        compareAtPrice
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      totalInventory
      options {
        name
        values
      }
    }
  }
`;

export default BookTemplate;
